.root {
  gap: 16px;
  padding: 16px;
}

.img {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: var(--primary-light);
  border-radius: 8px;
}

.btn {
  margin-top: auto;
}
