.root {
  gap: 16px;
  padding: 16px;
}

.btn {
  margin-top: auto;
}
.inputHelper {
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--secondary-light);
}

.selectLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #a3acb3;
}

.selectText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #a3acb3;
}
