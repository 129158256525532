.root {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  background-color: var(--light);
  border-radius: 8px;
  cursor: pointer;
  transition: color .3s, box-shadow .3s;

  @media (hover) {
    &:hover {
      color: var(--primary);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    }

  }

  &:active .slider:after {
    width: 18px;
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    color: var(--secondary-light);
  }

  .label {
    flex: 1;
    cursor: pointer;
  }

  .input {
    @media (hover) {
      &:focus-visible + .slider {
        outline: 2px solid rgba(236, 91, 117, .5);
        outline-offset: 4px;
      }
    }

    &:checked + .slider {
      background-color: var(--primary);
      border-color: var(--primary);

      &:after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
        background-color: var(--white);
      }
    }
  }

  .slider {
    position: relative;
    display: block;
    width: 40px;
    height: 24px;
    border: 1px solid var(--light-gray);
    border-radius: 32px;
    transition: background-color .3s, border-color .3s;

    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      background-color: var(--light-gray);
      border-radius: 50%;
      transition: left .3s, transform .3s, width .3s, background-color .3s;
    }
  }
}