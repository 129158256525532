.root {
    padding: 16px;
  }
  
  .card {
    position: relative;
    display: grid;
    grid-template-columns: 64px 1fr;
    gap: 12px;
    margin-bottom: 16px;
    padding-right: 16px;
  }
  
  .cardImg {
    width: 64px;
    height: 64px;
    object-fit: cover;
    background-color: var(--secondary-light);
    border-radius: 8px;
  }
  
  .cardCol {
    align-self: center;
    overflow: hidden;
  }
  
  .cardArrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  
  .cardTitle {
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 26px;
  }
  
  .cardRow {
    font-size: 12px;
    line-height: 18px;
    color: var(--secondary-light);
  }
  
  .cardVenue {
    font-weight: 600;
    color: var(--primary);
  }
  
  .cardTime {
    font-weight: 300;
  
    b {
      font-weight: 600;
    }
  }
  
  .selectLabel {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: var(--secondary-light);
  }
  
  .selectPlaceholder {
    color: var(--secondary-light);
  }
  
  .inputHelper {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--secondary-light);
  }
  
  .btn {
    margin-top: auto;
  }
  .inputHelper {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--secondary-light);
  }
  