.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #a3acb3;
}

.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.25px;

  color: #333333;
}
