@import '../../assets/styles/mixins';

.header {
  display: flex;
  align-items: center;
  padding: 32px 16px;
  border-bottom: 1px solid var(--gray);
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: -9px;
  width: 26px;
  height: 26px;
}

.arrow {
  transform: scaleX(-1);
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  @include scrollbar(1px, var(--light), var(--light), 1px, 0);
}

.headerBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  direction: rtl;
  letter-spacing: -0.25px;
  color: #ec5b75;
}
