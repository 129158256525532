.root {
  padding: 16px;
}

.selectLabel {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--secondary-light);
}

.selectPlaceholder {
  color: var(--secondary-light);
}

.inputHelper {
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--secondary-light);
}

.btn {
  margin-top: auto;
}
