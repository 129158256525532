@import '../../../assets/styles/mixins';
.root {
  padding: 16px;
  padding-bottom: 0;
}
.relative {
  position: relative;
}
.btn {
  margin-top: auto;
}

.list {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 9999;
}

.short {
  .item {
    display: none;
  }
  .item {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      display: flex;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 18px;
  width: 100%;
  border-bottom: 1px solid #efefef;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #333333;
    margin-left: 18px;
    text-align: left;
    &:hover {
      color: rgba(51, 51, 51, 0.7);
    }
  }
  div {
    margin-left: auto;
  }
}

.change {
  width: 48px;
  height: 4px;
  display: flex;
  background: #efefef;
  border-radius: 2px;
  margin: 0 auto 16px;
  margin-top: 8px;
}
.blur {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 56px;
}

.container {
  height: auto;
  position: absolute;
  background: #ffffff;
  z-index: 9999;
  bottom: -70px;
  width: 100%;
  transition: all 0.3s ease;
  @include scrollbar(1px, var(--light), var(--light), 1px, 0);
}
.container2 {
  overflow: scroll;
  position: absolute;
  background: #ffffff;
  z-index: 9999;
  bottom: 20px;
  width: 100%;
  transition: all 0.3s ease;
  @include scrollbar(1px, var(--light), var(--light), 1px, 0);
}
.sorry {
  font-size: 14px;
  text-align: center;
  padding: 16px;
  font-weight: 600;
}

.opacity {
  opacity: 0.7;
}
