.subs {
  padding: 16px;
  border-bottom: 1px solid var(--gray);

  :global(.navBtn),
  .subsBtn {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  .subsBtn {
    gap: 8px;
    color: var(--primary-dark);
    background-color: var(--primary-light);
  }
}

.subsTime {
  margin-right: 4px;
  color: var(--primary);
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
}

.title {
  color: var(--secondary-light);
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 270px;
  font-weight: 600;
  padding-left: 3px;

  color: var(--primary);
  &_row {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
}
