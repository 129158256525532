.skeleton {
  &__template {
    display: flex;
    flex-direction: column;
  }
  &__messages_my {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    > span {
      > span {
        flex-direction: column;
      }
    }
  }
  &__messages_his {
    display: flex;
    flex-direction: column;
    > span {
      > span {
        flex-direction: column;
      }
    }
  }
}
