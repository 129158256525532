.label {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--secondary-light);
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 7px;
}

.hide {
  display: none;
}

.tag {
  padding: 6px 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background-color: var(--gray);
  border-radius: 48px;
  transition: color 0.3s, background-color 0.3s;

  @media (hover) {
    &:hover,
    &:focus-visible {
      color: var(--white);
      background-color: var(--primary);
    }
  }

  &:global(.selected) {
    color: var(--white);
    background-color: var(--primary);

    @media (hover) {
      &:hover,
      &:focus-visible {
        color: var(--secondary);
        background-color: var(--gray);
      }
    }
  }
}

.search {
  input[type='search'] {
    padding-left: 40px;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      content: url('../../assets/icons/delete.svg');
      cursor: pointer;
    }
  }
}
