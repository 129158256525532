.btn {
  margin-top: auto;
}

.column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  > label {
    width: 100%;
  }
}
