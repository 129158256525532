.root {
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1;
}

.img {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: var(--primary-light);
  border-radius: 8px;
  margin-bottom: 16px;
}

.btn {
  margin-top: auto;
}
