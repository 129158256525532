.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px 24px;
  border-bottom: 1px solid var(--gray);
}

.link {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.photo {
  width: 64px;
  height: 64px;
  object-fit: cover;
  background-color: var(--gray);
  border-radius: 50%;
}

.cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  gap: 2px;
  &__truncated {
    display: block !important;
    max-width: 218px;
  }
}

.chats {
  position: relative;
  box-sizing: content-box;
  padding: 24px;
  padding-right: 4px;
  width: 24px;
  height: 24px;
}

.chatsNum {
  position: absolute;
  top: 15px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  min-width: 19px;
  height: 19px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: var(--white);
  background-color: var(--primary);
  border: 1.5px solid var(--white);
  border-radius: 50%;
}

.city {
  width: 240px;
  > img {
    margin-right: 4px;
  }
}
