.root {
  display: block;
  padding: 16px;
  overflow-x: hidden;
}

.slider {
  overflow: visible;
  margin-bottom: 12px;
}

.img, .map {
  width: 544px;
  height: auto;
  object-fit: cover;
  background-color: var(--gray);
  border-radius: 8px;
  aspect-ratio: 4/3;
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-bottom: 8px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  font-weight: 600;
}

.location {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
}
