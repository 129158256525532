@import '../../../assets/styles/mixins';

.root {
  padding: 24px 16px 16px;
}

.resendBtn {
  margin-top: auto;
}
.phone {
  &__input {
    width: 100% !important;
    color: var(--secondary) !important;
    display: inline-block;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 24px !important;
    min-height: 48px !important;
    outline: none;
    background: var(--light) !important;
    border: 1px solid var(--light) !important;
    resize: none;
    transition: border-color 0.3s;
    vertical-align: middle;
    margin: 0;
  }
  &__btn {
    display: flex;
    background: var(--light) !important;
    border: 1px solid var(--light) !important;
    ul {
      @include scrollbar(4px, var(--primary), var(--light), 4px, 0);
    }
  }
  &__dropdown {
    bottom: -215px;
    min-width: 360px !important;
    @media (max-width: 420px) {
      min-width: 300px !important;
    }
  }
}
