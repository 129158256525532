.root {
  padding: 24px 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 16px;
}

.imgWrap {
  position: relative;
  overflow: hidden;
  background-color: var(--light);
  border-radius: 8px;
  aspect-ratio: 1;

  .img {
    object-fit: cover;
    aspect-ratio: 1;
  }

  .imgRemoveBtn {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
  }

  .imgAddBtn {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.footer {
  margin-top: auto;
}
