.root {
  position: relative;
}

.like {
  position: absolute;
  top: -58px;
  right: 16px;
  display: flex;
  width: 24px;
  height: 24px;
}
