@import 'reset';
@import 'vars';
@import 'helpers';
@import 'mixins';
@import '../fonts/Inter/stylesheet';

body {
  font-family: var(--font);
  position: relative;
  font-size: 14px;
  line-height: 1.58;
  font-weight: 400;
  letter-spacing: -0.25px;
  color: var(--secondary);
  background-image: linear-gradient(
    to right top,
    rgba(236, 91, 117, 0.25),
    rgba(240, 124, 162, 0.25),
    rgba(237, 156, 199, 0.25),
    rgba(233, 187, 226, 0.25),
    rgba(234, 216, 242, 0.25),
    rgba(226, 228, 252, 0.25),
    rgba(225, 239, 255, 0.25),
    rgba(232, 248, 255, 0.25),
    rgba(209, 250, 254, 0.25),
    rgba(192, 251, 239, 0.25),
    rgba(191, 249, 213, 0.25),
    rgba(208, 244, 180, 0.25)
  );
  min-height: 1024px;
  height: 100vh;

  //position: fixed;
  inset: 0;
  @include scrollbar(4px, var(--primary), var(--light), 4px, 0);
  @media (max-width: 768px) {
    min-height: initial;
    background-image: none;
  }
}

.app {
  display: flex;
  flex-flow: column;
  margin-left: auto;
  margin-right: auto;
  // max-width: var(--small-container);
  max-width: 400px;
  background-color: var(--white);
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 864px;
  @media (max-width: 768px) {
    height: 100vh;
  }
}

h1,
.title {
  display: block;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}

a,
input,
button,
textarea,
select {
  @media (hover) {
    &:focus-visible {
      outline-color: rgba(236, 91, 117, 0.5);
      outline-offset: 4px;
    }
  }
}

a {
  color: var(--secondary);
  transition: color 0.3s;

  @media (hover) {
    &:hover,
    &:focus-visible {
      color: var(--primary);
    }
  }
}

svg {
  flex-shrink: 0;
}
.lower {
  text-transform: math-auto !important;
}
.navBtn {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 18px 8px 16px;
  width: 100%;
  min-height: 48px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  background-color: var(--light);
  border-radius: 8px;
  transition: color 0.3s, box-shadow 0.3s;
  text-transform: capitalize;
  &.lower {
    text-transform: inherit !important;
  }

  @media (hover) {
    &:hover,
    &:focus-visible {
      color: var(--primary);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value {
    color: var(--secondary-light);
  }
}

.details {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-light);
  &.row {
    display: flex;
    align-items: center;
  }
}

.row {
  display: flex;
  align-items: center;
}

.profile_type {
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #ec5b75;
}

.caption {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-light);
  &.red {
    color: var(--primary);
  }
}

.btnGroup {
  .navBtn {
    border-radius: 0;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--gray);
    }
  }
}
.textInitial {
  text-transform: initial;
}

input[type='text'],
input[type='password'],
input[type='email'],
// input[type='tel'],
input[type='number'],
input[type='search'],
input[type='date'],
textarea,
select {
  display: inline-block;
  vertical-align: middle;
  padding: 11px 16px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: var(--secondary);
  background-color: var(--light);
  border: 1px solid var(--light);
  border-radius: 8px;
  outline: none;
  appearance: none;
  resize: none;
  transition: border-color 0.3s;

  @media (hover) {
    &:hover,
    &:focus-visible {
      border-color: var(--secondary-light);
    }
  }

  &::placeholder {
    font-weight: 400;
    color: var(--secondary-light);
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
}

input[type='datetime-local'] {
  display: inline-block;
  vertical-align: middle;
  padding: 11px 16px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: var(--secondary);
  background-color: var(--light);
  border: 1px solid var(--light);
  border-radius: 8px;
  outline: none;
  appearance: none;
  resize: none;
  transition: border-color 0.3s;

  &::placeholder {
    font-weight: 400;
    color: var(--secondary-light);
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
}

.label-wrapper {
  display: inline-block;
  vertical-align: middle;
  padding: 11px 16px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: var(--secondary);
  background-color: var(--light);
  border: 1px solid var(--light);
  border-radius: 8px;
  outline: none;
  appearance: none;
  resize: none;
  transition: border-color 0.3s;
}
.inputWrap {
  position: relative;
  display: block;
  &.sex {
    > select {
      padding: 11px 23px 11px 16px;
    }
  }
  &.birth {
    > input {
      padding: 0;
    }
  }
  &.birth-iphone {
    height: 48px;
    > input {
      padding: 0;
      height: 0;
    }
  }

  .inputLabel {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: var(--secondary-light);
  }

  .innerLabel {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    pointer-events: none;
  }

  input,
  select,
  textarea {
    width: 100%;
  }

  input[type='date'] {
    text-align: right;
    color: var(--secondary-light);
    > option {
      direction: ltr;
    }
  }

  input[type='datetime-local'] {
    text-align: right;
    color: transparent;
    width: 0;
    border-color: none !important;
    &:hover {
      border-color: none !important;
    }
    > option {
      direction: ltr;
    }
  }

  select {
    text-align: right;
    direction: rtl;
    color: var(--secondary-light);
    > option {
      direction: ltr;
    }
  }

  input::-webkit-date-and-time-value {
    text-align: right;
  }

  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    position: absolute;
    inset: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    &::placeholder {
      display: none;
    }
  }
}

.datetime-local {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 14px;
  color: var(--secondary-light);
  pointer-events: none;
}

// /* Safari 11+ */
// @media not all and (min-resolution: 0.001dpcm) {
//   @supports (-webkit-appearance: none) and (stroke-color: transparent) {
//     .inputWrap {
//       &.birth {
//         > input {
//           padding: 11px 15px 11px 16px;
//         }
//       }
//     }
//   }
// }
// /* Test website on real Safari 11+ */

// /* Safari 10.1 */
// @media not all and (min-resolution: 0.001dpcm) {
//   @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
//     .inputWrap {
//       &.birth {
//         > input {
//           padding: 11px 15px 11px 16px;
//         }
//       }
//     }
//   }
// }

@mixin safari-only {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .inputWrap {
        &.birth {
          > input {
            padding: 11px 15px 11px 16px;
          }
        }
      }
    }
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: var(--container);
  width: 100%;
}

.cardWrap {
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

// Table
.table {
  tr {
    border-bottom: 1px solid var(--light);
  }

  th {
    vertical-align: middle;
    padding: 8px 16px;
    height: 50px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: var(--info);
  }

  td {
    vertical-align: middle;
    padding: 8px 16px;
    height: 60px;
  }
}

.tableInner {
  display: flex;
  gap: 16px;

  &Right {
    justify-content: right;
  }

  &Center {
    justify-content: center;
  }
}

// Helper classes
.flex {
  display: flex;
  flex-wrap: wrap;

  &Column {
    flex-direction: column;
  }
}

.noWrap {
  flex-wrap: nowrap;
}

.gap25 {
  gap: 25px;
}

.mAuto {
  margin: auto;
}

.mxAuto {
  margin-left: auto;
  margin-right: auto;
}

.myAuto {
  margin-top: auto;
  margin-bottom: auto;
}

.fullWidth {
  width: 100%;
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.good {
  color: var(--success);
}

.alert {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.bold {
  font-weight: 700;
}

.semiBold {
  font-weight: 600;
}

.hover {
  transition: opacity 0.3s;

  @media (hover) {
    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.cardHover {
  cursor: pointer;
  transition: margin-top 0.3s, margin-bottom 0.3s;

  @media (hover) {
    &:hover,
    &:focus-visible {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
}

.noSelect {
  user-select: none;
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
}

.loader {
  display: flex;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  right: 50% !important;
  z-index: 9999;
}

// Animations
.pulse {
  @media (hover) {
    &:hover,
    &:focus-visible {
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  0% {
    opacity: 1;
  }
}
.bottom-box {
  position: fixed;
  bottom: 0;
  margin-bottom: calc(20px + env(keyboard-inset-height));
}

//SWIPER NAVIGATION

.swiper-button-next {
  background: #fdeff1;
  width: 40px;
  height: 48px;
  right: -16px;
  top: 170px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  &::after {
    content: url(../../../src/assets/icons/arrow-right.svg);
    font-family: unset;
    font-size: unset;
    right: 12px;
    position: absolute;
  }
}

.swiper-button-prev {
  background: #fdeff1;
  width: 40px;
  height: 48px;
  left: -16px;
  top: 170px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  &::after {
    content: url(../../../src/assets/icons/arrow-left.svg);
    font-family: unset;
    font-size: unset;
    left: 12px;
    position: absolute;
  }
}

.swiper-button-disabled {
  display: none;
}
