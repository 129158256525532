.box {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s, opacity 0.3s;

  &.show {
    max-height: 96px;
    opacity: 1;
  }
}

.btn {
  margin-top: auto;
}

.btn__delete {
  display: inline-flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  padding: 11px 16px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: var(--primary-grey);
  border: 1px solid var(--primary-grey);
  font-weight: 600;
  border-radius: 12px;
  transition: box-shadow 0.3s;
  @media (hover) {
    &:hover,
    &:focus-visible {
      color: var(--primary-grey);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}

.btn__logout {
  display: inline-flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: var(--primary-grey);
  border: 1px solid var(--primary-grey);
  font-weight: 600;
  border-radius: 12px;
  transition: box-shadow 0.3s;
  span {
    display: flex;
    align-items: center;
  }
  @media (hover) {
    &:hover,
    &:focus-visible {
      color: var(--primary-grey);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
