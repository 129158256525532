.root {
  display: flex;
  align-items: center;
  gap: 12px;
}

.img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}

.inner {
  overflow: hidden;
  flex: 1;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  min-height: 24px;

  .name {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.time {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-light);
}

.message {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-light);
}

.new {
  padding-left: 8px;
  padding-right: 8px;
  min-width: 24px;
  height: 24px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: var(--white);
  background-color: var(--primary);
  border-radius: 48px;
}
