.container {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 36px 0 0 0;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left_value,
.slider__right_value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #efefef;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #ec5b75;
  width: 100%;
  z-index: 2;
}

.slider__left_value {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #a3acb3;
  left: 2px;
  top: -32px;
}

.slider__right_value {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.25px;
  color: #333333;
  right: 6px;
  top: -32px;
}

.slider__left_value {
  left: 6px;
}

.slider__right_value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  &__left {
    z-index: 3;
  }
  &__right {
    z-index: 4;
  }
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
