.root {
  display: block;
  overflow-x: hidden;
  padding: 16px;
}

.slider {
  overflow: visible;
}

.slide {
  &:global.swiper-slide-active {
    .eventCardInfo,
    .eventCardFooter {
      opacity: 1;
    }
  }
}

.card {
  height: calc(100vh - 123px);

  :global {
    .eventCardInfo,
    .eventCardFooter {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}
