.root {
  padding: 24px 16px 16px;
}

.map {
  margin-top: 24px;
  margin-bottom: 32px;
  border-radius: 8px;
}

.btn {
  margin-top: auto;
}
