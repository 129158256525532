.root {
  display: block;
  padding: 16px;
  overflow-x: hidden;
  position: relative;
}

.slider {
  overflow: visible;
  margin-bottom: 12px;
}

.img {
  width: 544px;
  height: auto;
  object-fit: cover;
  background-color: var(--gray);
  border-radius: 8px;
  aspect-ratio: 4/3;
}

.location {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
}

.buyBtn {
  justify-content: space-between;
  margin-top: 50px;
}

.removeBtn {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 368px;
  color: var(--primary-grey) !important;
}
