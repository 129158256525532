.root {
  padding: 16px;

  :global(.navBtn){
    text-transform: none;
  }
}

.separator {
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  height: 1px;
  background-color: var(--gray);
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--secondary-light);
}
