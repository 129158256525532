.root {
  padding: 24px 16px;
}

.card {
  position: relative;
  display: grid;
  grid-template-columns: 64px 1fr;
  gap: 12px;
  margin-bottom: 16px;
  padding-right: 16px;
}

.cardImg {
  width: 64px;
  height: 64px;
  object-fit: cover;
  background-color: var(--secondary-light);
  border-radius: 8px;
}

.cardCol {
  align-self: center;
  overflow: hidden;
}

.cardTitle {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 26px;
}

.cardAddress {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cardArrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
