@import '../../assets/styles/mixins';

.root {
  display: block;
  // overflow: scroll;
  padding: 16px;
  @include scrollbar(1px, var(--primary), var(--light), 1px, 0);
}

.slider {
  overflow: visible;
}

.slide {
  &:global.swiper-slide-active {
    .profileCardInfo,
    .btn {
      opacity: 1;
    }
  }
}

.card {
  height: calc(100vh - 24px);

  :global {
    .profileCardInfo,
    .btn {
      opacity: 0;
      transition: opacity 0.3s;
    }

    .btn {
      margin-top: auto !important;
    }
  }
}
