.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.img {
  margin-bottom: 12px;
  width: 544px;
  height: auto;
  object-fit: cover;
  background-color: var(--gray);
  border-radius: 8px;
  aspect-ratio: 4/3;
}

.info {
  margin-bottom: auto;
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-bottom: 4px;
}

.people {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
}

.details {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-light);
}

.venue {
  font-weight: 600;
  color: var(--primary);
}

.time {
  font-weight: 300;

  b {
    font-weight: 600;
  }
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.tag {
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--primary);
  background-color: var(--primary-light);
  border-radius: 20px;
}

.buyQRCodes {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 12px;
  transition: box-shadow 0.3s;
  margin-bottom: 8px;
}

.QRCodesSold {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #a3acb3;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 16px;
}

.cancel_event {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid #d0d1d3 !important;
  color: #d0d1d3 !important;
}

.separator {
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  height: 1px;
  background-color: var(--gray);
  padding: 0.5px;
}

.root {
  padding: 16px;

  :global(.navBtn){
    text-transform: none;
  }
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--secondary-light);
}

