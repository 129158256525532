.root {
  padding-top: 24px;
  padding-bottom: 64px;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }

  a {
    color: var(--red);
  }
}

.section {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
}

.map_wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 734px;
}

.map_inner {
  position: absolute;
  z-index: 2;
  top: 130px;
  bottom: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 288px;
}

.search {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
}

.event {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));

  .event_img {
    margin-bottom: 12px;
    width: 100%;
    height: 192px;
    border-radius: 8px;
    object-fit: cover;
  }

  .event_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;

    b {
      font-size: 16px;
      line-height: 24px;
    }

    span {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }
  }

  .event_location {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--secondary);
  }

  .event_desc {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  .event_tel {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: var(--primary);
  }

  .event_link {
    margin-top: auto;
    padding: 11px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--red);
    border: 1px solid #d0d1d3;
    border-radius: 8px;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;

    @media (hover) {
      &:hover,
      &:focus-visible {
        color: var(--white);
        background-color: var(--red);
        border-color: var(--red);
      }
    }
  }

  .event_close {
    position: absolute;
    top: -4px;
    right: -64px;
  }
}

// input[placeholder='Enter address'] {
//   height: 48px;
//   background: #fff;
//   border-radius: 8px;
//   padding: 12px;
//   max-width: 240px;
//   width: 100%;
//   outline: none;
// }

// a[class='reset'] {
//   position: absolute;
//   top: 8px;
//   right: 0;
//   background: transparent;
//   border: navajowhite;
//   &:hover {
//     background: transparent;
//     border: navajowhite;
//   }
// }

// div[class='geosearch leaflet-bar leaflet-control leaflet-control-geosearch leaflet-geosearch-custom'] {
//   border: none;
// }

form[autocomplete='none'] {
  background: white;
  max-width: 280px;
  > div {
    div {
      padding-left: 8px;
      margin-bottom: 5px;
      cursor: pointer;
      &:hover {
        background: #f2f2f2;
      }
    }
  }
}

a[title='Search on map'] {
  position: absolute;
  top: -75px;
  left: 34px;
}

form[autocomplete='none'] {
  top: -76px;
  left: 63px;
}

.search__block {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  max-width: 288px;
  width: 100%;
  position: absolute;
  background: #fafafb;
  border-radius: 8px;
  height: 48px;
  top: 70px;
  z-index: 9999;
  top: 10px;
  left: 10px;
  padding-left: 40px;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    background: transparent;
    outline: none;
  }
  svg {
    position: absolute;
    left: 15px;
  }
}

.search__block_s {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  max-width: 288px;
  width: 100%;
  position: absolute;
  background: #fafafb;
  border-radius: 8px;
  height: 48px;
  top: 10px;
  z-index: 9999;
  left: 10px;
  padding-left: 40px;

  > div {
    display: flex;
    align-items: center;
    div {
      display: flex;
    }
  }
  input {
    background: transparent;
    outline: none;
  }
  svg {
    position: absolute;
    left: 15px;
  }
}

.search__container_s {
  right: 423px !important;
  top: 50px !important;
  position: absolute;
  left: 0;
  z-index: 9999;
  max-width: 280px !important;
  width: 100% !important;
  padding: 10px;
  background-color: white;

  box-sizing: border-box;
  li {
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
  > li {
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}

.search__container {
  left: 335.23px !important;
  top: 314.668px !important;
  z-index: 9999;
  max-width: 280px !important;
  width: 100% !important;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
  > li {
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}
