.root {
  display: flex;
  flex-direction: column;
}

.photo {
  margin-bottom: 12px;
  width: 544px;
  height: auto;
  object-fit: cover;
  background-color: var(--gray);
  border-radius: 8px;
  aspect-ratio: 1/1;
}

.name {
  display: flex;
  align-items: center;
  gap: 2px;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.tag {
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--primary);
  background-color: var(--primary-light);
  border-radius: 20px;
}

.bio {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
