.skeleton {
  &__template {
    display: flex;
    align-items: center;
    &_col {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
    &_img {
      > span {
        > span {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
}
