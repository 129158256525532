.root {
  gap: 16px;
  padding: 16px;
}

.btn {
  margin-top: auto;
}

.date {
  padding: 0 !important;
}

.row {
  display: flex;
  justify-content: space-between;
  label {
    &:first-child {
      max-width: 231px;
      width: 100%;
    }
    &:last-child {
      max-width: 96px;
      width: 100%;
    }
  }
}

//AVATAR BLOCK
.avatar {
  &_block {
    display: flex;
    align-items: center;
  }
  &_img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
  }
  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #a3acb3;
    margin-bottom: 4px;
  }
  &_input {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #ec5b75;
      text-align: left;
      cursor: pointer;
    }
  }
}

.edit_number {
  display: inline-block;
  vertical-align: middle;
  padding: 11px 16px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: var(--secondary);
  background-color: var(--light);
  border: 1px solid var(--light);
  border-radius: 8px;
  outline: none;
  appearance: none;
  resize: none;
  transition: border-color 0.3s;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error {
  display: block;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--primary);
}
