:root {
  --font: 'Inter', sans-serif;
  --subfont: var(--font);

  --primary: #ec5b75;
  --primary-light: #fdeff1;
  --primary-dark: #9b112a;
  --primary-grey: #A3ACB3;

  --secondary: var(--dark);
  --secondary-light: #a3acb3;
  --secondary-dark: var(--black);

  --error: #e44b4b;
  --warning: #ffae00;
  --info: #4fbcfa;
  --success: #2cc197;
  --light: #fafafb;
  --dark: #333333;

  --white: #ffffff;
  --black: #000000;
  --gray: #efefef;
  --light-gray: #d0d1d3;

  --container: 1390px;
  --small-container: 576px;
}
