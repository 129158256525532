.root {
  gap: 24px;
  padding: 24px 16px 16px;
}

.photoWrap {
  overflow: hidden;
  width: 100%;
  height: auto;
  background: {
    image: url('../../../assets/icons/placeholder.svg');
    position: center;
    size: cover;
    repeat: no-repeat;
  };
  border-radius: 8px;
  aspect-ratio: 1/1;
}

.photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: var(--gray);
  aspect-ratio: 1/1;
}

.btn {
  margin-top: auto;
  cursor: pointer;

  @media (hover) {
    &:focus-within {
      color: var(--white);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    }
  }
}
