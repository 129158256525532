.hero {
  margin-top: 64px;
  padding-bottom: 32px;
  border-bottom: 1px solid #efefef;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #333333;
    margin-bottom: 32px;
    display: flex;
    img {
      object-fit: contain;
      margin-right: 4px;
      width: 28px;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #333333;
    margin-bottom: 24px;
  }
}
.langBtn {
  gap: 8px;
  padding: 12px 16px;
  cursor: pointer;
}

.btn {
  margin-top: auto;
}
