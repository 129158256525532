.root {
  padding: 24px 16px;
}

.noteBtn {
  color: var(--primary);
  font-weight: 600;
}

.noChatsImg {
  margin-top: -8px;
  margin-bottom: 16px;
  border-radius: 8px;
}
