//AVATAR BLOCK
.avatar {
  &_block {
    display: flex;
    align-items: center;
  }
  &_img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
  }
  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #a3acb3;
    margin-bottom: 4px;
  }
  &_input {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #ec5b75;
      text-align: left;
      cursor: pointer;
    }
  }
}

.root {
  gap: 16px;
  padding: 16px;
}

.btn {
  margin-top: auto;
}

.row {
  display: flex;
  justify-content: space-between;
  label {
    &:first-child {
      max-width: 231px;
      width: 100%;
    }
    &:last-child {
      max-width: 96px;
      width: 100%;
    }
  }
}
