.fadeout {
  animation: fadeOut 2s ease forwards;
  position: absolute;
  top: 150px;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 75px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 24px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  white-space: nowrap;
  color: #ffffff;
  padding: 8px 16px;
  z-index: 9999;
  &__hidden {
    display: none;
  }
}
//Make fade out animation and then hide the element
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
