@import '../../../assets/styles/mixins';

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid var(--gray);
}

.back {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
}

.input__message {
}

.arrow {
  transform: scaleX(-1);
}

.user {
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  h1 {
    margin-left: 8px;
    margin-right: 5px;
  }
}

.main {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  flex: 1;
  padding: 8px;
  @include scrollbar(4px, var(--primary), var(--light), 4px, 0);
}
.main_empty {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  overflow: auto;
  flex: 1;
  padding: 8px;
  @include scrollbar(4px, var(--primary), var(--light), 4px, 0);
}

.noMessages {
  margin: auto;
  max-width: 155px;
  width: 100%;
  text-align: center;
  color: var(--secondary-light);

  svg {
    margin-bottom: 12px;
  }
}

.time {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--secondary-light);
  text-align: center;
}

@keyframes slide-top {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.message {
  position: relative;
  margin-bottom: 4px;
  margin-left: auto;
  padding: 8px 12px 26px;
  width: fit-content;
  min-width: 70px;
  max-width: 85%;
  color: var(--primary-dark);
  background-color: var(--primary-light);
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &.incoming {
    margin-left: 0;
    color: var(--secondary);
    background-color: var(--light);
  }

  &.short {
    padding-right: 74px;
    padding-bottom: 8px;

    .messageTime {
      bottom: 8px;
    }
  }
}

.messageTime {
  position: absolute;
  bottom: 4px;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-light);
}

.footer {
  display: flex;
  align-items: center;
  z-index: 100;
  width: 100%;
  //  margin-top: auto;
  gap: 8px;
  padding: 8px;
  background-color: var(--light);
  border-top: 1px solid var(--gray);

  input {
    flex: 1;
    padding: 7px 12px;
    min-height: 38px;
    font-size: 14px;
    line-height: 22px;
    background-color: var(--white);
    border-color: var(--gray) !important;
  }

  button {
    width: 32px;
    height: 32px;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.down {
  &__btn {
    position: absolute;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__quantity {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ec5b75;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #ffffff;
  }
}
