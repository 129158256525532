.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #a3acb3;
  margin-bottom: 4px;
}

.buttons {
  display: flex;
  background: #efefef;
  border-radius: 8px;
  padding: 4px;
  width: 100%;
  justify-content: space-between;
}

.button {
  height: 32px;
  width: 100%;
  border-right: 1px solid #a3acb3;
  &:last-child {
    border-right: none;
  }

  &.active {
    background: #ec5b75;
    border-radius: 7px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;

    color: #ffffff;
  }
}
