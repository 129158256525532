.root {
  padding: 20px 16px;
}
.date {
  padding: 0;
  height: 48px;
  > input {
    height: 0px;
  }
}

.btn {
  margin-top: auto;
}
