.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 11px 16px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 12px;
  transition: box-shadow .3s;

  @media (hover) {
    &:hover, &:focus-visible {
      color: var(--white);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    }
  }

  &:disabled {
    opacity: .5;
    user-select: none;
    pointer-events: none;
  }

  &.outlined {
    color: var(--primary);
    background-color: var(--white);
    border: 1px solid var(--light-gray);

    @media (hover) {
      &:hover, &:focus-visible {
        color: var(--primary);
      }
    }
  }
}
